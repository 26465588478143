//jQuery.noConflict();
jQuery.event.special.touchstart = {

  setup: function( _, ns, handle ){
    if ( ns.includes("noPreventDefault") ) {
      this.addEventListener("touchstart", handle, { passive: false });
    } else {
      this.addEventListener("touchstart", handle, { passive: true });
    }
  }

};


var $=jQuery.noConflict();

$(document).ready(function(){


        // owlCarousel
        //$('.owl-carousel').owlCarousel();
        $('.slide-fotos').owlCarousel({
            pagination: true,
            nav: true,
            loop: false,
            dots: true,
            margin: 20,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1000: {
                    items: 2
                }
            }

        });


        $('.slide-principal').owlCarousel({
            pagination: true,
            nav: true,
            loop: true,
            dots: true,
            margin: 0,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            //animateIn: 'fadeIn',
            //animateOut: 'fadeOut',
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }
        });

        $('.slide-testimonios-foto').owlCarousel({
            pagination: true,
            nav: true,
            loop: true,
            dots: false,
            margin: 10,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            //animateIn: 'fadeIn',
            //animateOut: 'fadeOut',
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }
        });


        $('.slide-news').owlCarousel({
            //pagination: true,
            nav: true,
            loop: false,
            dots: true,
            margin: 10,
            // stagePadding: 100,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1000: {
                    items: 3
                },
                1200: {
                    items: 3
                },
                1500: {
                    items: 3
                },
            }

        });

        $('.slide-single').owlCarousel({
            //pagination: false,
            nav: true,
            loop: false,
            //center:true,
            dots: true,
            margin: 0,
            items: 1,
            autoplay: true,
            //URLhashListener: true,
            //autoplayHoverPause: true,
            //startPosition: 'URLHash',
            //animateIn: 'fadeIn',
            //animateOut: 'fadeOut'

        });




        $(".owl-prev").html('<span class="icon-rrss ei ei-arrow_carrot-left_alt2"></span>');
        $(".owl-next").html('<span class="icon-rrss ei ei-arrow_carrot-right_alt2"></span>');



        //  Masonry: https://masonry.desandro.com/
        var $grid = $('.mosaic-grid').imagesLoaded(function () {
            // init Masonry after all images have loaded
            $grid.masonry({
                temSelector: '.mosaic-grid-item',
                // use element for option
                columnWidth: '.mosaic-grid-sizer',
                percentPosition: true
            });
        });


        /*
        // Bootstrap Auto-Hiding Navbar 
        $(".header").autoHidingNavbar();


        // Highlight the top nav as scrolling occurs
        $(window).scroll(function () {
            if ($(document).scrollTop() > 250) {
                $('.header').addClass('shrink');
            } else {
                $('.header').removeClass('shrink');
            }

            if ($(document).scrollTop() > 600) {
                $('.slide-principal').addClass('shrink');
            } else {
                $('.slide-principal').removeClass('shrink');
            }

        });
        */


        // Select all links with hashes
        $('.main-nav a[href*="#"]')
            // Remove links that don't actually link to anything
            .not('[href="#"]')
            .not('[href="#0"]')
            .click(function (event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000, function () {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });

        
        $('.dropdown').hover(function(){ 
            //$('.dropdown-toggle', this).trigger('click'); 
        });



        // Mobile menu collapse onclick
        $('#menu-menu li ul li a').on('click', function () {
            $(".navbar-toggle").toggleClass("collapsed");
            $(".navbar-collapse").toggleClass("in");
        });



        // toggle menu
        $("body").on("click", ".header .menu-item a", function (e) {
            $(".navbar-toggler").trigger("click");
        });




        // Meni
        $('.navbar-toggler').click(function () {
            $('.navbar-toggler').toggleClass('collapsed');
            //$(this).addClass('current');
            $('.full-menu').toggleClass('active');
            var $target = $(this).attr('href');
            $($target).addClass('active');
            return false;
        });





        // Video
        $(".video-inside").on("click", function () {
            var elm = $(this),
                conts = elm.contents(),
                le = conts.length,
                ifr = null;
            for (var i = 0; i < le; i++) {
                if (conts[i].nodeType == 8) ifr = conts[i].textContent;
            }
            elm.addClass("player").html(ifr);
            elm.off("click");
        });

        // toggle video
        $("body").on("click", function (e) {

        });






        // Anchor scroll
        // jQuery for page scrolling feature - requires jQuery Easing plugin
        $('a.anchor').bind('click', function (event) {
            var $anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: ($($anchor.attr('href')).offset().top - 0)
            }, 1250, 'easeInOutExpo');

            event.preventDefault();
        });











        // Animate on scroll
        // Detect request animation frame
        var scroll = window.requestAnimationFrame ||
            // IE Fallback
            function (callback) { window.setTimeout(callback, 1000 / 60) };
        var elementsToShow = document.querySelectorAll('.show-on-scroll');

        function loop() {

            Array.prototype.forEach.call(elementsToShow, function (element) {
                if (isElementInViewport(element)) {
                    element.classList.add('is-visible');
                } else {
                    element.classList.remove('is-visible');
                }
            });

            scroll(loop);
        }

        // Call the loop for the first time
        loop();

        // Helper function from: http://stackoverflow.com/a/7557433/274826
        function isElementInViewport(el) {
            // special bonus for those using jQuery
            if (typeof jQuery === "function" && el instanceof jQuery) {
                el = el[0];
            }
            var rect = el.getBoundingClientRect();
            return (
                (rect.top <= 0
                    && rect.bottom >= 0)
                ||
                (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.top <= (window.innerHeight || document.documentElement.clientHeight))
                ||
                (rect.top >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
            );
        }



        // https://simpleparallax.com/

        var image = document.getElementsByClassName('parallax-bg');
        new simpleParallax(image, {
            delay: .6,
            transition: 'cubic-bezier(0,0,0,1)',
            //scale: 1.5
        });


        $(function () {
            var $el = $('.parallax-background');
            $(window).on('scroll', function () {
                var scroll = $(document).scrollTop();
                $el.css({
                    //'background-position':'50% '+(-.4*scroll)+'px'
                    'background-position': '100% ' + (+.4 * scroll) + 'px'
                });
            });
        });



        // magnificPopup for .image-link
        $('.image-link').magnificPopup({ type: 'image' });

        $('.popup').magnificPopup({
            delegate: 'a', // the selector for gallery item
            type: 'image',
            gallery: {
                enabled: true
            }
        });


        $('.gallery').each(function () { // the containers for all your galleries
            $(this).magnificPopup({
                delegate: 'a', // the selector for gallery item
                type: 'image',
                gallery: {
                    //enabled:true
                }
            });
        });


        $('.popup-gallery, .slide-single').magnificPopup({
            delegate: '.owl-item:not(.cloned) a',
            type: 'image',
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
                beforeOpen: function () {
                    // just a hack that adds mfp-anim class to markup 
                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                    this.st.mainClass = this.st.el.attr('data-effect');
                }
            },
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title') + '<small></small>';
                }
            }
        });


        // Popup
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,

            fixedContentPos: false
        });


        // Cambiar clases botones


        $('.woocommerce-MyAccount-content .woocommerce-Input').addClass('form-control');




        $('.single_add_to_cart_button').addClass('btn btn-primary');
        $('.single_add_to_cart_button').removeClass('button');


        $('.wc-forward').addClass('btn btn-primary');
        $('.wc-forward').removeClass('button');


        $('a.checkout-button ').addClass('btn');
        $('a.checkout-button ').removeClass('button');


        $('input[type="submit"]').addClass('btn');
        $('input[type="submit"]').removeClass('button');




        // Contact
        $('.wpcf7-form .wpcf7-text').addClass('form-control');
        $('.wpcf7-textarea').addClass('form-control');
        $('.wpcf7-date').addClass('form-control');
        $('.wpcf7-submit').addClass('btn btn-primary font-weight-bold text-uppercase');
        //$('.input-xlarge').addClass('form-control');



        fluidvids.init({
        selector: ['.contingut iframe'],
        players: ['www.youtube.com', 'player.vimeo.com']
        });








});
